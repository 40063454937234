import useHomepageLeader from '@/hooks/useHomepageLeader'
import Home from '@/components/home/pages/home'
import WithDefaultLayout from '@/components/generic/layouts/default'

const HomeWrapper = (props) => {
  const { hideLeader } = useHomepageLeader()

  const Component = WithDefaultLayout(Home, {
    hideLeader,
    showMobileGlobalNav: true,
    hideCookieConsentBanner: false,
  })

  return <Component {...props} />
}

export default HomeWrapper
