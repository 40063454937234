import PropTypes from 'prop-types'
import {
  Alignment,
  Box,
  Divider,
  LocationIcon,
  PersonIcon,
  variants,
} from '@resident-advisor/design-system'
import { dateFormat } from 'lib/dateFormat'
import unescape from 'lodash/unescape'
import { eventRoute } from 'lib/routes'
import getEventImage from 'lib/getEventImage'
import ASPECT_RATIO, { formatAspectRatio } from 'lib/aspectRatio'
import IMAGE_TYPE from '@/enums/image-type'
import testIds from '@/enums/testIds'
import featureSwitches from '@/enums/feature-switches'
import CmsContent from '@/components/generic/cms-content'
import Link from '@/components/generic/link'
import Heading from '@/components/generic/heading'
import Date from '@/components/generic/date'
import MetaLink from '@/components/generic/meta-link'
import MetaText from '@/components/generic/meta-text'
import ResponsiveImage from '@/components/generic/responsive-image/ResponsiveImage'
import { useFeatureSwitch } from '@/context/FeatureSwitchesContext'
import SavedEventsButton, {
  EventSavedSourceType,
  SavedEventsButtonType,
} from '@/components/user/pages/saved-events/saved-events-button/SavedEventsButton'

const EventPickCard = ({
  date,
  contentUrl,
  title,
  venue,
  interestedCount,
  pick,
  queueItEnabled,
  withFlyer,
  flyerFront,
  newEventForm,
  images,
  lazyRootRef,
  isSaved,
  isInterested,
  id,
  artists,
  promoters,
  tickets,
  isTicketed,
}) => {
  const enableSavedEvents = useFeatureSwitch(featureSwitches.enableSavedEvents)

  return (
    <Alignment flexDirection="column" data-testid={testIds.eventPickCard}>
      {withFlyer && (
        <Box mb={3}>
          <Link
            variant={variants.text.heading.m}
            {...eventRoute(contentUrl, queueItEnabled)}
          >
            <ResponsiveImage
              url={getEventImage(
                { flyerFront, newEventForm, images },
                IMAGE_TYPE.flyerFront
              )}
              aspect={ASPECT_RATIO['ISO-216']}
              alt={`'${title}' flyer image`}
              sizes={{ m: '25vw' }}
              lazyRootRef={lazyRootRef}
              properties={{
                fit: 'crop',
                ar: formatAspectRatio(ASPECT_RATIO['ISO-216']),
                quality: 50,
              }}
            />
          </Link>
        </Box>
      )}
      <Alignment flexDirection="row" justifyContent="space-between">
        <Date
          color="secondary"
          variant={variants.text.heading.s}
          date={date}
          format={dateFormat(date)}
        />
        {enableSavedEvents && (
          <SavedEventsButton
            trackingData={{
              venue,
              artists,
              promoters,
              tickets,
              isTicketed,
              source: EventSavedSourceType.PicksSection,
            }}
            eventId={id}
            isInterested={isInterested}
            interestedCount={interestedCount}
            isSaved={isSaved}
            type={SavedEventsButtonType.EventCardIcon}
          />
        )}
      </Alignment>
      <Heading>
        <Link
          variant={variants.text.heading.m}
          {...eventRoute(contentUrl, queueItEnabled)}
        >
          {unescape(title)}
        </Link>
      </Heading>

      <Box mt={2}>
        {venue &&
          (venue.live ? (
            <MetaLink
              Icon={LocationIcon}
              text={venue.name}
              href={venue.contentUrl}
            />
          ) : (
            <MetaText Icon={LocationIcon} text={venue.name} />
          ))}
        <MetaText Icon={PersonIcon} text={interestedCount} />
      </Box>

      <Divider my={2} borderColor="accent" />

      {pick && <CmsContent content={pick.blurb} />}
    </Alignment>
  )
}

EventPickCard.propTypes = {
  date: PropTypes.string.isRequired,
  contentUrl: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  pick: PropTypes.object.isRequired,
  venue: PropTypes.object,
  interestedCount: PropTypes.number.isRequired,
  queueItEnabled: PropTypes.bool,
  flyerFront: PropTypes.string,
  withFlyer: PropTypes.bool,
  lazyRootRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  images: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      filename: PropTypes.string.isRequired,
      alt: PropTypes.string,
      type: PropTypes.oneOf(Object.values(IMAGE_TYPE)),
      crop: PropTypes.object,
    })
  ),
  newEventForm: PropTypes.bool,
  isSaved: PropTypes.bool,
  isInterested: PropTypes.bool,
  id: PropTypes.string,
  artists: PropTypes.array,
  promoters: PropTypes.array,
  tickets: PropTypes.array,
  isTicketed: PropTypes.bool,
}

export default EventPickCard
