import isBefore from 'date-fns/isBefore'
import isAfter from 'date-fns/isAfter'
import getConfig from 'next/config'
import { isMobile as checkIsMobile } from 'lib/checkScreenWidth'
import { useEffect, useState } from 'react'
import { useServerTime } from '@/context/ServerTimeContext'
import useBodyWidth from '@/hooks/useBodyWidth'

const { publicRuntimeConfig } = getConfig()

const useHomepageLeader = () => {
  const [hideLeader, setHideLeader] = useState(true)
  const serverTime = useServerTime()
  const width = useBodyWidth()
  const isMobile = checkIsMobile(width)

  useEffect(() => {
    const isAfterStart =
      publicRuntimeConfig.HOMEPAGE_LEADER.START &&
      isAfter(serverTime, new Date(publicRuntimeConfig.HOMEPAGE_LEADER.START))
    const isBeforeEnd =
      publicRuntimeConfig.HOMEPAGE_LEADER.END &&
      isBefore(serverTime, new Date(publicRuntimeConfig.HOMEPAGE_LEADER.END))

    if (!isMobile && isAfterStart && isBeforeEnd) {
      setHideLeader(false)
    }
  }, [isMobile, serverTime])

  return {
    hideLeader,
  }
}

export default useHomepageLeader
