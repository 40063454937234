/* eslint-disable react/no-unknown-property */
import PropTypes from 'prop-types'
import Head from 'next/head'
import { sizes as breakpoints } from '@resident-advisor/design-system'
import formatImgproxyUrl from 'lib/formatImgproxyUrl'
import {
  generateSrcSets,
  generateSizes,
} from '@/components/generic/responsive-image'

const PreloadFirstItem = ({ isFirstItem, imageUrl, imageUrlMobile }) => {
  if (!isFirstItem) {
    return null
  }

  const imgproxyProperties = {
    rt: 'fill',
  }

  const sizes = generateSizes()

  return (
    <Head>
      <link
        rel="preload"
        href={formatImgproxyUrl(imageUrlMobile, imgproxyProperties)}
        imagesrcset={generateSrcSets(imageUrlMobile)}
        imagesizes={sizes}
        as="image"
        media={`(max-width: ${breakpoints.m}px)`}
      />
      <link
        rel="preload"
        href={formatImgproxyUrl(imageUrl, imgproxyProperties)}
        imagesrcset={generateSrcSets(imageUrl)}
        imagesizes={sizes}
        as="image"
        media={`(min-width: ${breakpoints.m + 0.1}px)`}
      />
    </Head>
  )
}

PreloadFirstItem.propTypes = {
  isFirstItem: PropTypes.bool,
  imageUrl: PropTypes.string.isRequired,
  imageUrlMobile: PropTypes.string.isRequired,
}

export { PreloadFirstItem }
