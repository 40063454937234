import { useQuery } from '@apollo/client'
import PropTypes from 'prop-types'
import {
  SubSection,
  Grid,
  Column,
  variants,
} from '@resident-advisor/design-system'
import format from 'date-fns/format'
import addDays from 'date-fns/addDays'
import { useServerTime } from '@/context/ServerTimeContext'
import QueryResultHandler from '@/components/generic/query-result-handler'
import GET_TODAYS_REVIEWS from './TodaysReviewsQuery'
import AlbumReview from './AlbumReview'
import EPReview from './EPReview'

const TodaysReviews = () => {
  const serverTime = useServerTime()
  const today = format(serverTime, 'yyyy-MM-dd')

  const queryResult = useQuery(GET_TODAYS_REVIEWS, {
    variables: {
      dateFrom: addDays(serverTime, -7),
      dateTo: today,
    },
  })

  return (
    <QueryResultHandler
      {...queryResult}
      markup={TodaysReviewsMarkup}
      dataKey={['albumReview', 'epReview']}
    />
  )
}

const TodaysReviewsMarkup = ({ data: { albumReview, epReview } }) => (
  <SubSection.Stacked variant={variants.section.secondary}>
    <Grid lCols={12} mCols={1} sCols={1} mWrap sWrap>
      <Column lSpan={5}>
        <AlbumReview data={albumReview} />
      </Column>
      <Column lSpan={1} />
      <Column lSpan={5}>
        <EPReview data={epReview} />
      </Column>
      <Column lSpan={1} />
    </Grid>
  </SubSection.Stacked>
)

TodaysReviewsMarkup.propTypes = {
  data: PropTypes.shape({
    albumReview: PropTypes.array,
    epReview: PropTypes.array,
  }).isRequired,
}

export { TodaysReviewsMarkup }
export default TodaysReviews
