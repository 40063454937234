import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { reviewByIdRoute } from 'lib/routes'
import arrayHasData from 'lib/arrayHasData'
import MusicCard from '@/components/editorial/cards/music'
import messages from '@/messages/music'

const AlbumReview = ({ data }) => {
  const intl = useIntl()
  if (!arrayHasData(data)) {
    return null
  }

  const album = data[0]

  return (
    <MusicCard
      urlFormatter={reviewByIdRoute}
      contentTitle={intl.formatMessage(messages.albumReview)}
      {...album}
      musicType="EP Review"
    />
  )
}

AlbumReview.propTypes = {
  data: PropTypes.array.isRequired,
}

export default AlbumReview
