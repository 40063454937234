import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { Section } from '@resident-advisor/design-system'
import messages from '@/messages/music'
import trackingIds from '@/tracking/tracking-ids'
import LatestPodcastOrExchange from '@/components/editorial/sub-sections/latest-podcast-or-exchange'
import ReviewsSubSection from '@/components/editorial/sub-sections/reviews'
import TodaysReviews from '@/components/editorial/sub-sections/todays-reviews'
import { PaddedStack } from '@/components/generic/padded'
import Ad from '@/components/generic/ad'

const MusicSection = ({ showPodcast }) => {
  const intl = useIntl()

  return (
    <Section
      title={intl.formatMessage(messages.music)}
      data-tracking-id={trackingIds.homeMusic}
      variant="secondary"
    >
      <PaddedStack>
        {showPodcast && <LatestPodcastOrExchange type="podcast" />}
        <TodaysReviews />
        <ReviewsSubSection
          type="SINGLE"
          contentType="ALL"
          orderBy="LATEST"
          viewMoreHref="/reviews"
          variant="secondary"
        />
        <Ad variant={Ad.variants.HPTO_5} />
      </PaddedStack>
    </Section>
  )
}

MusicSection.propTypes = {
  showPodcast: PropTypes.bool,
}

export default MusicSection
