import { gql } from '@apollo/client'
import { REVIEW_FIELDS_NO_DATE } from '@/queries/GetReviewsWithoutDateQuery'

const GET_TODAYS_REVIEWS = gql`
  query GET_TODAYS_REVIEWS($dateFrom: DateTime, $dateTo: DateTime) {
    albumReview: reviews(
      limit: 1
      type: ALBUM
      orderBy: LATEST
      dateFrom: $dateFrom
      dateTo: $dateTo
    ) {
      ...reviewFields
    }
    epReview: reviews(
      limit: 1
      type: SINGLE
      orderBy: LATEST
      contentType: LONGFORM
      dateFrom: $dateFrom
      dateTo: $dateTo
    ) {
      ...reviewFields
    }
  }
  ${REVIEW_FIELDS_NO_DATE}
`

export { REVIEW_FIELDS_NO_DATE }
export default GET_TODAYS_REVIEWS
